import LandingPage from './landing/LandingPage';
import './less/main.css'
function App() {
  return (
    <div className="App">
      <LandingPage/>
    </div>
  );
}

export default App;
