import React, {Component, Fragment} from 'react';
import './LandingPage.css';
import headshot from './Headshot.jpg';

export default class Header extends Component {


    render() {
        return <Fragment>
            <div className='headerBlock'>
                <img className='placeholder' src={headshot} alt='headshot'/> <span className='title'>Baby Whisperer Birth Services</span>
            </div>
        </Fragment>

    }
}

