import React, {Component, Fragment} from 'react';
import wordcloud from './Rainbow Word Cloud.jpg';
import {ColorList} from '../common/colors';

export default class Content extends Component {
    wordList = [
        ['baby', 80, ColorList.blue],
        ['doula', 50, ColorList.orange],
        ['birth', 60, ColorList.red],
        ['photography', 30, ColorList.red],
        ['cast', 10, ColorList.green],
        ['care', 20, ColorList.violet],
        ['family', 40, ColorList.blue],
        ['joy', 20, ColorList.green]
    ];

    render() {
        return <Fragment>
            <div className='row'>
                <div className='col'>
                    <img src={wordcloud} alt="doula word cloud" className='img img-fluid'/>
                </div>
                <div className='col p-3 normal'>
                    Hello, I’m Loran with Baby Whisperer Birth Services. My mission is to provide birthing parents and
                    families individualized, caring support to help you advocate for your goals during the entire childbirth
                    journey. I love all things baby!
                </div>
            </div>
        </Fragment>
    }
}