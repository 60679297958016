import React, {Component, Fragment} from 'react';

export default class Footer extends Component {
    render() {
        return <Fragment>
            <div className='row mt-3'>
                <div className='col text-center'>
                    Phone:  608-618-BABY (2229)
                </div>
            </div>
            <div className='row'>
                <div className='col text-center'>
                    Email: Doula.Partera.Loran@gmail.com
                </div>
            </div>
        </Fragment>
    }
}